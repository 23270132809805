const serverBaseSSL = "https://app.ttdesignco.com";

const UrlConstant = {
  API_ENDPOINT: "https",
  REACT_APP_API_URL: `${serverBaseSSL}/api`,
  REACT_APP_DATABASE_URL: `${serverBaseSSL}`,
  REACT_APP_VAPID_KEY:
    "AAAA7ZefGyI:APA91bGNGwx88KrcOhhxOXKOX-6Qu0OEq8yK6YbdbcfybGXiiihi2HF2pjlECx5qjP7wfghqdTSBBOVG8H898QcUH3HFyTQ47a5DjAH3GctxmoKYQ1nfcdu3nSFIoXmmRS1vtFx0-z0g",
};
export default UrlConstant;
